<template>
  <div class="manage-project">
    <div class="container fluid">
      <project-table
        v-show="isLoaded"
        :canEdit="canEdit"
        :canAdd="false"
        :canDelete="canDelete"
        :canViewReport="canViewReport"
        :canAllocateAgent="false"
        :canAllocateBanker="canAllocateBanker"
        :canAllocateLawyer="canAllocateLawyer"
        :getProjectsAPI="projectAPI.getProjects"
        :deleteProjectAPI="projectAPI.deleteProject"
        :changeStatusAPI="projectAPI.updateStatus"
        @toAgentAllocation="toAgentAllocationPage"
        @toBankerAllocation="toBankerAllocation"
        @toLawyerAllocation="toLawyerAllocation"
        @toProjectReport="toProjectReport"
      ></project-table>
    </div>
  </div>
</template>

<script>
import ProjectTable from "@/modules/Project/components/ProjectTable";
import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectAPI from "@/modules/Project/api/project";

export default {
  name: "manage-project",
  components: {
    ProjectTable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      canEdit: false,
      canDelete: false,
      canAllocateBanker: false,
      canAllocateLawyer: false,
      canViewReport: false,

      isLoaded: false,

      projectAPI: new ProjectAPI(ManagerRoles.DEVELOPER)
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await this.getAccessPermission();
      this.isLoaded = true;
      this.$store.commit("setIsLoading", false);
    },
    async getAccessPermission() {
      this.canEdit = this.canDelete = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "manageProjects.modify"
      );
      this.canAllocateBanker = this.canAllocateLawyer = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "manageProjectSales.modify"
      );
      this.canViewReport = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "manageProjects.report"
      );
    },
    toAgentAllocationPage(id) {
      this.$router.push({
        name: "ProjectAllocateAgents",
        params: { id: id }
      });
    },
    toBankerAllocation(id) {
      this.$router.push({
        name: "ProjectAllocateBankers",
        params: { id: id }
      });
    },
    toLawyerAllocation(id) {
      this.$router.push({
        name: "ProjectAllocateLawyers",
        params: { id: id }
      });
    },
    toProjectReport(id) {
      this.$router.push({
        name: "ProjectSalesReport",
        params: { projectId: id }
      });
    }
  }
};
</script>

<style lang="scss"></style>
